import { KeycloakService } from "keycloak-angular";
import { KeycloakConfig } from "keycloak-js";

export function initializeKeycloak(keycloak: KeycloakService) {
  return async () => {
    let keycloakConfig: KeycloakConfig = {
      url: "http://dev-om-alb-1982086794.ap-south-1.elb.amazonaws.com:8081",
      realm: "om",
      clientId: "om-web-app",
    };

    return keycloak.init({
      config: keycloakConfig,
      initOptions: {
        onLoad: "check-sso",
        silentCheckSsoRedirectUri:
          window.location.origin + "/assets/silent-check-sso.html",
        checkLoginIframe: false,
      },
      bearerExcludedUrls: ["/assets", "/clients/public"],
      shouldAddToken: (request) => {
        const { method, url } = request;

        const isGetRequest = "GET" === method.toUpperCase();
        const acceptablePaths = ["/assets", "/clients/public"];
        const isAcceptablePathMatch = acceptablePaths.some((path) =>
          url.includes(path)
        );
        return !(isGetRequest && isAcceptablePathMatch);
      },
    });
  };
}
